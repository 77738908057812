<template>
  <div class="index-box">
    <resumeHeadS title="首页" />
    <div class="box-topImg">
      <div class="block">
        <el-carousel trigger="click" height="228px" :interval="interval">
          <el-carousel-item v-for="(item, index) in bannerList.banner" :key="index">
            <img :src="item.cover" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="index-subject">
      <div class="top-title">
        <div class="select">功能选择</div>
        <div class="selectText">根据个人需要选择相应功能</div>
      </div>
      <div class="top-list">
        <div
          class="top-listBox"
          v-for="(item, index) in chooseList"
          :key="index"
          @click="skipFn(item)"
        >
          <div class="topImg">
            <img :src="item.url" alt="" />
          </div>
          <div class="topText">
            <div>
              {{ item.text }}
            </div>
            <div class="topIcon">
              <img :src="item.icon" alt="" />
            </div>
          </div>
          <div class="topDescribe">
            {{ item.describeOne }}
          </div>
          <div class="topDescribe">
            {{ item.describeTwo }}
          </div>
        </div>
      </div>
      <div class="my-curriculum">
        <div class="top-title">
          <div class="select">我的简历</div>
          <div class="selectText">来添加你的与众不同简历 (前10条)</div>
        </div>
        <div v-loading="loading">
          <div class="curriculum-list" v-if="resumeObj && resumeObj.length">
            <div
              class="curriculum-box"
              v-for="(item, index) in resumeObj"
              :key="index"
              @click="onSkipResumePageFn('edit', item)"
            >
              <div class="curriculum-left">
                <div class="left-chart">
                  <img src="@/assets/image/resumeIndex/pdf.png" alt="" />
                </div>
                <div class="left-details">
                  <div class="left-detailsText">
                    <div v-if="index !== resumeObjIndex">
                      <div v-if="item.item.resume_name">
                        {{ item.item.resume_name }}
                      </div>
                      <div v-else style="color: #999">
                        {{ item.item.name || 'xxx' }}-{{
                          item.intention && item.intention.length
                            ? item.intention[0].intentionName
                            : '岗位'
                        }}-{{ item.item.num_work_experience || '' }}年/{{
                          item.education_experience && item.education_experience.length
                            ? item.education_experience[0].end_time_year
                            : ''
                        }}届毕业
                      </div>
                    </div>
                    <div
                      class="detailsTextImg"
                      @click.stop="resumeObjIndexFn(index)"
                      v-if="index !== resumeObjIndex"
                    >
                      <img src="@/assets/image/resumeIndex/daochu1.png" alt="" />
                    </div>

                    <div @click.stop v-if="resumeObjIndex === index">
                      <el-input
                        ref="editTask"
                        v-model="item.item.resume_name"
                        placeholder="请输入内容"
                        @blur="blurFn(item, index)"
                        @keyup.enter.native="$event.target.blur()"
                      ></el-input>
                    </div>
                    <!-- <div
                      class="accomplish"
                      @click.stop="accomplishFn(item, index)"
                      v-if="resumeObjIndex === index"
                    >
                      完成
                    </div> -->
                  </div>
                  <div class="detailsTime">更新时间：{{ item.item.update_time }}</div>
                </div>
              </div>
              <div class="curriculum-right">
                <div
                  class="curriculum-rightBox"
                  v-loading="item.resumeLoading.flag"
                  @click.stop="toGetPdf(item)"
                >
                  <div class="rightBoxImg">
                    <img src="@/assets/image/resumeIndex/zhenduan.png" alt="" />
                  </div>
                  <div class="rightBoxText">诊断</div>
                </div>
                <div class="curriculum-rightBox" @click.stop="educeFn(item)">
                  <div class="rightBoxImg">
                    <img src="@/assets/image/resumeIndex/daochu.png" alt="" />
                  </div>
                  <div class="rightBoxText">导出</div>
                </div>
                <div class="curriculum-rightBox" @click.stop="getResumeCopyFn(item)">
                  <div class="rightBoxImg">
                    <img src="@/assets/image/resumeIndex/fuzhi.png" alt="" />
                  </div>
                  <div class="rightBoxText">复制</div>
                </div>
                <div class="curriculum-rightBox delete el-icon-delete" @click.stop>
                  <el-popconfirm title="这是一段内容确定删除吗？" @confirm="confirm(item)">
                    <div class="rightBoxText" slot="reference">删除</div>
                  </el-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Add-resume">
          <button class="btn" @click="onSkipResumePageFn('add')">新增简历</button>
        </div>
      </div>
    </div>
    <!-- 生成简历文件流-默认选择简历模板1 -->
    <div style="position: absolute; left: -9999px; top: -9999px">
      <preview1 />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import preview1 from '@/views/resume/pdfExport/preview1'
import resumeHeadS from '@/components/resumeHeadS'

export default {
  components: {
    preview1,
    resumeHeadS
  },
  data() {
    return {
      timeS: null, //定时器状态
      resumeObjTimsS: null, //获取下标的定时器
      resumeObjIndex: null, //当前用户点击的下标
      resumeListIsRoute: true, //简历列表是否能够跳转
      loading: false,
      bannerList: [], //轮播图数据
      interval: 4000,
      resumeObj: null, //简历信息
      hideDelay: null, // 隐藏延迟标志位
      //功能选择列表
      chooseList: [
        {
          text: '简历编辑',
          icon: require('@/assets/image/resumeIndex/箭头 (2).png'),
          url: require('@/assets/image/resumeIndex/1.png'),
          describeOne: '在线编辑简历是HR推荐的智能简历制作',
          describeTwo: '工具，有大量智能优化建议。',
          path: '/resume/edit?type=add'
        },
        {
          text: '简历诊断',
          icon: require('@/assets/image/resumeIndex/箭头 (2).png'),
          url: require('@/assets/image/resumeIndex/2.png'),
          describeOne: '诊断简历中的问题教你突出简历亮点，',
          describeTwo: '帮你提升面试通过率！',
          path: '/resume/diagnosis'
        },
        {
          text: '简历模板',
          icon: require('@/assets/image/resumeIndex/箭头 (2).png'),
          url: require('@/assets/image/resumeIndex/3.png'),
          describeOne: '精选各种简历模版，涵盖各个职位，内容 ',
          describeTwo: '信息完整，可直接修改套用。',
          path: '/resume/resume_template'
        }
      ],
      upyunData: null, // 又拍云签名
      resumeName: null // 简历名称
    }
  },
  created() {
    this.getSlideshowFn()
    this.getMeRedactListFn()
    this.getUpYunSignFn()
  },
  computed: {
    ...mapState(['currentResumeEditId', 'userInfo'])
  },
  methods: {
    ...mapActions([
      'getMeRedactList',
      'rmoverDeleteResume',
      'getSlideshowList',
      'resumeGenerateSave',
      'getResumeCopy',
      'getUpYunSign',
      'resumeFileUpyun',
      'getResumeGenerateContent'
    ]),
    ...mapMutations({
      removerContents: 'REMOVER_CONTENTS'
    }),
    async getMeRedactListFn() {
      this.loading = true
      this.resumeObj = await this.getMeRedactList()
      // 处理json数据格式
      if (this.resumeObj.data.length) {
        this.resumeObj = this.resumeObj.data.map((item, index) => {
          const intention = JSON.parse(item.intention)
          const education_experience = JSON.parse(item.education_experience)
          const resumeLoading = { flag: false }
          return {
            item,
            intention: intention,
            education_experience: education_experience,
            resumeLoading
          }
        })
      }
      this.loading = false
    },
    // 获取轮播图数据
    async getSlideshowFn() {
      const res = await this.getSlideshowList()
      this.bannerList = res
    },
    // 跳转简历编辑页面
    onSkipResumePageFn(type, item = null) {
      if (this.resumeListIsRoute) {
        if (type === 'nav') {
          this.$router.push(`/resume/edit`)
        } else if (type === 'edit') {
          this.$router.push(`/resume/edit?id=${item.item.id}`)
        } else if (type === 'add') {
          this.$router.push('/resume/edit?type=add')
        }
      }
    },
    // 通过功能选择进行跳转
    skipFn(item) {
      if (this.resumeListIsRoute) this.$router.push(item.path)
    },
    // 删除简历记录方法
    async confirm(item) {
      if (this.resumeListIsRoute) {
        const res = await this.rmoverDeleteResume({ id: item.item.id })
        if (res.code === 200) {
          // this.$message({
          //   message: '删除成功',
          //   type: 'success',
          //   offset: 70,
          // })
          //获取最新简历
          this.getMeRedactListFn()
        } else {
          this.$message({
            message: '删除失败,请稍后重试',
            type: 'warning'
          })
        }
      }
    },
    // 点击跳转到预览简历导出页面
    educeFn(item) {
      if (this.resumeListIsRoute) {
        const { href } = this.$router.resolve({
          path: `/resume/pdfPreview?id=${item.item.id}` //跳转路径
        })
        window.open(href, '_blank')
      }
    },
    // 点击修改简历名称
    async accomplishFn(item, index) {
      // 先判断是否为空
      if (item.item.resume_name === null || item.item.resume_name.trim() === '') {
        // this.$message({
        //   message: '请填写您的简历名称哦！',
        //   type: 'warning',
        //   offset: 70
        // })
      } else {
        const data = {
          all_result_n: null,
          id: item.item.id,
          resume_name: item.item.resume_name
        }
        const res = await this.resumeGenerateSave(data)
        this.resumeObjIndex = null
        if (res.code) {
          // this.$message({
          //   message: '修改成功',
          //   type: 'success',
          //   offset: 70,
          // })
          this.getMeRedactListFn()
        } else {
          this.$message.error('请稍后重试')
        }
      }
    },
    // 点击记录当前下标
    resumeObjIndexFn(index) {
      clearTimeout(this.resumeObjTimsS)
      this.resumeListIsRoute = false
      this.resumeObjIndex = index
      this.resumeObjTimsS = setTimeout(() => {
        this.$refs.editTask[0].focus()
      }, 200)
    },
    // 输入框失去焦点
    blurFn(item, index) {
      // 设置隐藏延迟，300毫秒后触发隐藏方法
      this.hideDelay = setTimeout(() => {
        this.hideInputAndButton(item, index) // 调用隐藏输入框和完成按钮的方法
      }, 300)
    },
    // 隐藏输入框和完成按钮的方法
    hideInputAndButton(item, index) {
      clearTimeout(this.timeS)
      this.timeS = setTimeout(() => {
        this.resumeListIsRoute = true
      }, 200)
      this.accomplishFn(item, index)
      this.resumeObjIndex = null
    },
    // 复制简历
    async getResumeCopyFn(item) {
      await this.getResumeCopy({ id: item.item.id })
      this.getMeRedactListFn()
    },
    // 获取简历名字
    getName(item) {
      this.resumeName = item.item.resume_name
      if (this.resumeName === null) {
        //拼接导出文件名字
        this.resumeName = `${item.item.name || 'xxx'}-${
          item.intention.length ? item.intention[0].intentionName : '岗位'
        }-${item.item.num_work_experience || ''}年/${
          item.education_experience.length ? item.education_experience[0].end_time_year : ''
        }届毕业`
      }
    },
    // 获取简历流方法
    async toGetPdf(item, tile = '', download = false) {
      item.resumeLoading.flag = true
      await this.getResumeGenerateContent({ id: item.item.id })
      this.getName(item)
      // download：false为不下载
      this.getPdf(tile, download).then(res => {
        this.UploadPdf(item, res)
      })
    },
    // 上传pdf接口
    async UploadPdf(item, res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.resumeName
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
      item.resumeLoading.flag = false
      this.diagnoseFn(item)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      await this.resumeFileUpyun(data)
    },
    // 点击诊断
    diagnoseFn(item) {
      this.$router.push({
        path: '/resume/diagnosis',
        query: {
          resumeId: item.item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.index-box
  padding-bottom 47px
.box-topImg
  .block
    margin-bottom 24px
    /deep/ .el-carousel__indicator--horizontal .el-carousel__button
      width 7px
      height 7px
      background #fff
      border 1px solid #ffffff
      border-radius 50%
  /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button
    width 7px
    height 7px
    border 1px solid #003DBB
    background #003DBB
    border-radius 50%
.index-subject
  padding 39px 50px 40px 50px
  box-sizing border-box
  margin 0 auto
  width 1200px
  height auto
  background #FFFFFF
  box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
  border-radius 10px
  margin-bottom 47px
.top-title
  display flex
  margin-bottom 17px
  .select
    font-size 25px
    font-family Source Han Sans CN
    font-weight bold
    color #333333
    letter-spacing 1px
  .selectText
    font-size 14px
    font-family Source Han Sans CN
    font-weight 400
    color #999999
    margin-top 13px
    margin-left 14px
    letter-spacing 1px
.top-list
  display flex
  margin-bottom 65px
  .top-listBox
    box-sizing border-box
    padding 42px 0 0 0
    width 346px
    height 375px
    background #FFFFFF
    box-shadow 0px 0px 27px 0px rgba(0, 61, 187, 0.08)
    border-radius 10px
    transition background-color 0.3s, border 0.3s
    display flex
    flex-direction column
    align-items center
  .top-listBox:nth-child(2)
    box-sizing border-box
    margin 0 30px
  .top-listBox:hover
    cursor pointer
    box-shadow 0px 0px 30px 0px rgba(0, 61, 187, 0.2)
    .topText
      color #2b5bc4
    .topIcon
      display block
  .topImg
    border-radius 50%
  .topText
    display flex
    align-items center
    margin-top 26px
    margin-bottom 19px
    height 21px
    font-size 22px
    font-family Source Han Sans CN
    font-weight 500
    color #333333
    .topIcon
      display none
      background #FFFFFF
      border-radius 50%
      margin-top 5px
      img
        width 100%
        height 100%
  .topDescribe
    font-size 14px
    font-family Source Han Sans CN
    font-weight 400
    color #b3b3b3
    margin-bottom 5px
.my-curriculum
  .curriculum-list
    margin-top 23px
    .curriculum-box
      cursor pointer
      margin-bottom 20px
      padding 0 30px 0 25px
      box-sizing border-box
      width 1100px
      height 60px
      background #F8FAFD
      border-radius 10px
      display flex
      justify-content space-between
      align-items center
      .curriculum-left
        display flex
        .left-chart
          width 32px
          height 33px
          margin-right 11px
        .left-details
          .left-detailsText
            display flex
            height 14px
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #333333
            .detailsTextImg
              cursor pointer
              width 12px
              height 12px
              margin-left 7px
              margin-top 3px
            .accomplish
              margin 10px 0 0 7px
              font-size 14px
            .accomplish:hover
              text-decoration underline
          .detailsTime
            margin-top 9px
            height 11px
            font-size 12px
            font-family Source Han Sans CN
            font-weight 400
            color #999999
      .curriculum-right
        display flex
        height 100%
        .curriculum-rightBox
          display flex
          align-items center
          margin-right 24px
          height 100%
          .rightBoxImg
            margin-right 1px
          .rightBoxText
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #1149bf
          .rightBoxText:hover
            cursor pointer
            text-decoration underline
        .curriculum-rightBox.delete
          color #F56C6C
          .rightBoxText
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #F56C6C
            margin-left 1px
          .rightBoxText:hover
            cursor pointer
            color #F56C6C
            text-decoration underline
  .Add-resume
    text-align center
    margin-top 40px
    .btn
      cursor pointer
      text-align center
      line-height 40px
      width 110px
      height 40px
      background #003DBB
      border-radius 4px
      font-size 18px
      font-family Source Han Sans CN
      font-weight 400
      color #FFFFFF
      border 0
    .btn:hover
      opacity 0.9
</style>
